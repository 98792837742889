import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Fragment, useRef } from 'react';
import ButtonNew from './ButtonNew';

export type Props = {
    showDialog: boolean;
    title?: string;
    unsavedChangesText?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    cancelNavigation: () => void;
    confirmNavigation: () => void;
};

export default function UnsavedChangesModal({
    showDialog,
    title = 'You have unsaved changes',
    confirmButtonText = 'Return to edit',
    cancelButtonText = 'Continue',
    unsavedChangesText = 'You have not published your changes. If you leave, they will not be saved.',
    cancelNavigation,
    confirmNavigation,
}: Props) {
    const continueButtonRef = useRef(null);

    return (
        <Transition.Root show={showDialog} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={continueButtonRef} onClose={cancelNavigation}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center pt-[225px]">
                        <Dialog.Panel className="flex h-[203px] w-[400px] transform overflow-hidden rounded-sm bg-white transition-all">
                            <button
                                className="absolute right-[24px] top-[14px] shadow-none transition-shadow hover:shadow-xl"
                                onClick={cancelNavigation}
                            >
                                <XMarkIcon className="h-[18px] w-[18px] text-black" />
                            </button>
                            <div className="flex flex-col p-6">
                                <Dialog.Title as="h4" className="text-[20px] font-medium text-black">
                                    {title}
                                </Dialog.Title>
                                <div className="pt-4 text-sm font-normal text-black">{unsavedChangesText}</div>
                                <div className="flex pt-8">
                                    <ButtonNew onClick={cancelNavigation}>{confirmButtonText}</ButtonNew>
                                    <ButtonNew className="ml-4" buttonStyle="secondary" onClick={confirmNavigation}>
                                        {cancelButtonText}
                                    </ButtonNew>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
