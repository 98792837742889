import { useState, useCallback } from 'react';
import {
    unstable_Blocker as Blocker,
    unstable_BlockerFunction as BlockerFunction,
    unstable_useBlocker as useBlocker,
} from 'react-router-dom';

type HookReturnType = {
    showPrompt: boolean;
    confirmNavigation: () => void;
    cancelNavigation: () => void;
    actionWrapper: () => void;
};

export function useUnsavedChanges(isDirty: boolean, actionToConfirm?: () => void): HookReturnType {
    const [isConfirmating, setIsConfirmating] = useState(false);

    const actionWrapper = isConfirmating
        ? () => {
              setIsConfirmating(false);
              actionToConfirm?.();
          }
        : () => setIsConfirmating(true);

    const handleBlockedNavigation = useCallback<BlockerFunction>(
        ({ historyAction, nextLocation, currentLocation }) =>
            isDirty && historyAction === 'PUSH' && nextLocation.pathname !== currentLocation.pathname,
        [location, isDirty]
    );

    const blocker: Blocker = useBlocker(handleBlockedNavigation);

    const showPrompt = isConfirmating || blocker.state !== 'unblocked';
    const confirmNavigation = isConfirmating ? actionWrapper : () => blocker.proceed?.();
    const cancelNavigation = isConfirmating ? () => setIsConfirmating(false) : () => blocker.reset?.();

    return { showPrompt, confirmNavigation, cancelNavigation, actionWrapper };
}
